.loginBody{
    min-height: 100vh;
    min-width: 100vw;
    background-size: cover;
    background-image: url("/public/blackGround.jpg");
    background-repeat: no-repeat;
    background-position: center;
}

.loginText {
    float: left;
    margin-top: 35vh;
    margin-left: 15vw;
}
