.site-layout-content {
    min-height: 280px;
    padding: 24px;
}
#components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}
.logo{
    text-align: right;
}
.body{
    min-height: 1600px;
    background-size: cover;
    background-image: url("/public/blackGround.jpg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 50px;
}
.ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
}
/*备案logo*/
.beian {
    background-image: url("/public/beian.png")
}

